/*

This just makes sure we take up 100% of the available page-space for use in the
application.

Usually, if left to it's own devices, this will not automatically stretch to use
all of the browser's window – which would mean that all children when declaring
'100%' in width or height would not use the full height & width, but only the
amount that would be in accordance with the largest manually defined element on
the page.

*/
:root,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

/*

The root of the react-application:

This makes sure that the height and width of the main element used by react has
the context of being 100% height and width of the whole browser page by default.

The above selectors for :root, and body are required for this to work.

*/
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
